import '@datadog/browser-logs/bundle/datadog-logs';
import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';

const token = process.env.DATADOG_CLIENT_TOKEN;
const env = process.env.DATADOG_ENV;
const enabled = process.env.DATADOG_ENABLED === 'true';

let initExecuted = false;

function init(correlationId: string) {
  if (!enabled) return;

  if (window.DD_LOGS) {
    window.DD_LOGS.init({
      clientToken: token as string,
      site: 'datadoghq.eu',
      service: 'dashboard',
      env: env as string,
      version: env as string,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: [],
      sessionSampleRate: 100,
    });

    window.DD_LOGS.setGlobalContextProperty('correlationID', correlationId);
    initExecuted = true;
  }
}

function info(correlationId: string, message: string, messageContext?: Object) {
  if (!initExecuted) {
    init(correlationId);
  }
  window.DD_LOGS && window.DD_LOGS.logger.info(message, messageContext);
}

function warn(correlationId: string, message: string, messageContext?: Object) {
  if (!initExecuted) {
    init(correlationId);
  }
  window.DD_LOGS && window.DD_LOGS.logger.warn(message, messageContext);
}

function error(correlationId: string, message: string, messageContext?: Object, error?: Error) {
  if (!initExecuted) {
    init(correlationId);
  }
  window.DD_LOGS && window.DD_LOGS.logger.error(message, messageContext, error);
}

export default (_context: Context, inject: Inject) => {
  inject('loggerInfo', info);
  inject('loggerWarn', warn);
  inject('loggerError', error);
};

import type { Route } from 'vue-router';
import { BrandHelper } from '~/utils/BrandHelper';
import { Brand } from '~/models/Brand';

export interface AuthenticatedContext {
  store: { commit: Function; getters: any; dispatch: Function };
  redirect: (string: string) => any;
  route: Route;
}

export default async function ({ store, redirect, route }: AuthenticatedContext) {
  if (!process.browser) return;

  const isAuth = localStorage.getItem('auth');
  const browserLanguage = navigator.language || 'en';
  const preferredLanguage = browserLanguage.split('-')[0];
  const currentBrand = BrandHelper.getBrand();

  const languageMap: Record<string, string> = {
    et: '/et',
    es: '/es',
    pt: '/pt',
    en: '',
  };
  const currentLanguagePrefix = languageMap[preferredLanguage] || '';

  if (isAuth) {
    const parsedAuth = JSON.parse(isAuth);

    if (!parsedAuth.authJwt) {
      parsedAuth.authJwt = parsedAuth.userId;
    }

    store.commit('AuthenticationModule/setQuery', parsedAuth);

    if (!route.path.includes('questionnaire')) {
      await store.dispatch('QuestionnaireModule/fetch');
    }

    const isQuestionnaireDone = store.getters['QuestionnaireModule/isPassed'];

    if (isQuestionnaireDone) {
      if (currentBrand === Brand.Frontnode && preferredLanguage === 'et' && !route.path.startsWith('/et')) {
        return redirect('/et' + route.fullPath);
      }
      if (currentBrand === Brand.Blockforia) {
        if (preferredLanguage === 'es' && !route.path.startsWith('/es')) {
          return redirect('/es' + route.fullPath);
        }
        if (preferredLanguage === 'pt' && !route.path.startsWith('/pt')) {
          return redirect('/pt' + route.fullPath);
        }
      }
    } else {
      const questionnairePath = `${currentLanguagePrefix}/questionnaire`;
      if (!route.path.startsWith(questionnairePath)) {
        return redirect(questionnairePath || '/questionnaire');
      }
    }
  } else {
    if (currentBrand === Brand.Frontnode && preferredLanguage === 'et' && !route.path.startsWith('/et')) {
      return redirect('/et' + route.fullPath);
    }

    if (currentBrand === Brand.Blockforia) {
      if (preferredLanguage === 'es' && !route.path.startsWith('/es')) {
        return redirect('/es' + route.fullPath);
      }
      if (preferredLanguage === 'pt' && !route.path.startsWith('/pt')) {
        return redirect('/pt' + route.fullPath);
      }
    }
  }
}
